<template>
  <ion-page>
    <Header title="Riwayat Poin" />
    <ion-content :fullscreen="true">
      <ion-refresher slot="fixed" @ionRefresh="doRefresh($event)">
        <ion-refresher-content></ion-refresher-content>
      </ion-refresher>
      <ion-list>
        <ion-item v-for="h in histories" :key="h.id">
          <ion-label>
            {{ h.meta.title ? h.meta.title : 'Poin berhasil di tambahkan' }}
            <div class="sub-label">
              <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4.99992 9.16666C7.30111 9.16666 9.16659 7.30118 9.16659 5C9.16659 2.69881 7.30111 0.833328 4.99992 0.833328C2.69873 0.833328 0.833252 2.69881 0.833252 5C0.833252 7.30118 2.69873 9.16666 4.99992 9.16666Z" stroke="#545454" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M5 2.5V5L6.66667 5.83333" stroke="#545454" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
              {{ $filters.dateFormat(h.created_at, 'full') }}
            </div>
          </ion-label>
          <ion-note slot="end" class="amount" :class="{'debit': h.amount > 0}">{{ h.amount }}</ion-note>
        </ion-item>
      </ion-list>
      <ion-infinite-scroll
        @ionInfinite="fetchTransaction"
        threshold="100px"
        id="infinite-scroll"
        :disabled="isDisabled"
      >
        <ion-infinite-scroll-content
          loading-spinner="bubbles"
          loading-text="Loading more data...">
        </ion-infinite-scroll-content>
      </ion-infinite-scroll>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonContent, IonPage, IonRefresher, IonRefresherContent,
  IonList, IonItem, IonLabel, IonNote,
  IonInfiniteScroll, IonInfiniteScrollContent,
} from '@ionic/vue';
import Header from '@/components/headerPage'
export default {
  components: {
    IonContent,
    IonPage,
    Header,
    IonRefresher, IonRefresherContent,
    IonList, IonItem, IonLabel, IonNote,
    IonInfiniteScroll, IonInfiniteScrollContent
  },
  data () {
    return {
      isDisabled: false,
      histories: [],
      page: 1
    }
  },
  mounted() {
    this.fetchTransaction()
  },
  methods: {
    async doRefresh(e) {
      this.page = 1;
      this.histories = []
      this.isDisabled = false
      await this.fetchTransaction()
      e.target.complete();
    },
    async fetchTransaction(e) {
      let data = await this.$api.get('/api/point/history', { params: { page: this.page }})
      if(data.meta.last_page == this.page) {
        this.isDisabled = true;
      }

      data.data.forEach(c => {
        this.histories.push(c)
      })

      if(e && e.type == 'ion-infinite')
        e.target.complete();

      this.page++
    }
  }
}
</script>

<style lang="scss">
.sub-label {
  font-size: 12px;
  color: #545454;
  margin-top: 5px;
  display: flex;
  align-items: center;
  svg {
    margin-right: 10px;
  }
}
.amount {
  color: #EB5757;
  font-size: 16px;
  &.debit {
    color: #219653;
  }
}
</style>
